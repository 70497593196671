@font-face {
    font-family: 'OpenSans-Regular';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/OpenSans-Regular.ttf');
}

@font-face {
    font-family: 'OpenSans-SemiBold';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/OpenSans-SemiBold.ttf');
}

@font-face {
    font-family: 'OpenSans-Bold';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/OpenSans-Bold.ttf');
}
