@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");

*,
a,
h1,
h2,
h3,
h4,
h5,
h6,
li,
img,
small {
    list-style: none;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    scroll-behavior: smooth;
}

body {
    padding: 0 0 0;
}

.show-for-d-only {
    display: none;
}

.scroll-offset {
    display: block;
    position: relative;
    top: -60px;
    visibility: hidden;
}

h2 {
    font-size: 20px;
    font-weight: bold;
    color: #14356e;
    margin-bottom: 10px;
}

.statval__subheadline3 {
    font-size: 28px;
    font-weight: bold;
    color: rgba(215, 16, 16, 0.75);
    margin-bottom: 16px;
}

section {
    text-align: center;
    background: #fff;
}

.overlay {
    position: fixed;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
    transition: all .5s;
}

.overlay.active {
    opacity: 1;
    pointer-events: all;
}

.container {
    padding: 0 15px;
}

.header {
    height: 60px;
    background: linear-gradient(to right, #163771, #2d64a6);
    color: #fff;
    border-bottom: 1px solid #729ed5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
}

.header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}


.header__button {
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    font-size: 0;
    top: 7px;
}

.header .path1,
.path2 {
    stroke-dasharray: 105;
    stroke-dashoffset: -71;
    transition: 1s all ease;
}

.header .cross {
    stroke-dashoffset: 68;
}

.header .mline {
    stroke-dasharray: 41;
    stroke-dashoffset: -82;
    transition: 1s all ease;
}

.header .hide {
    stroke-dasharray: 43;
    stroke-dashoffset: 130;
}

.header .svgburg {
    position: absolute;
    right: 15px;
    top: 10px;
    z-index: 5;
}

.fixed-banner {
    position: fixed;
    top: 59px;
    text-align: center;
    width: 100%;
    color: #fff;
    border-bottom: 1px solid #729ed5;
    background: linear-gradient(to right, #163771, #2d64a6);
    padding: 20px 0;
    z-index: 2;
    font-style: italic;
    box-shadow: 0 5px 6px rgba(000, 000, 000, 0.2);
}

.nav__list {
    position: fixed;
    top: 60px;
    right: -100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(82px);
    width: 70%;
    padding: 20px;
    box-shadow: -3px 0 2px rgba(000, 000, 000, 0.2);
    z-index: 4;
    transition: all .3s ease-in-out;
}

.nav__list.open {
    right: 0;
}

.nav__list .nav__list-item a {
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    text-align: left;
    transition: 0.25s all;
    text-shadow: -3px 3px 2px rgba(000, 000, 000, 0.2);
}

.nav__list-item+.nav__list-item {
    margin-top: 40px;
}

.hero {
    padding: 12px 0 21px;
    background: linear-gradient(to right, #163771, #2d64a6);
    color: #fff;
    text-align: center;
}

.hero__headline {
    font-size: 22px;
    font-weight: 600;
    padding: 0 25px;
    line-height: 1.4;
    margin-bottom: 25px;
    color: #fff;
    animation: 1s ease-out 0s 1 slideFromLeft;
}

.hero__content {
    animation: 1s ease-out 0s 1 slideFromRight;
}

.hero__subheadline {
    font-weight: bold;
    margin-bottom: 8px;
}

.hero__text {
    font-size: 14px;
    line-height: 20px;
}

.hero__cta {
    color: #fff;
    padding: 8px 12px;
    border: 1px solid #ffba00;
    border-radius: 19px;
    text-decoration: none;
    font-size: 14px;
    text-align: center;
    transition: 0.25s all;
    display: block;
    max-width: 136px;
    margin: 14px auto 0;
}

.hero__cta:hover {
    background: #fff;
    color: #14356e;
    border: 1px solid #fff;
}

.research {
    padding: 30px 0 25px;
    background: #fff;
}

.research__subheadline {
    font-weight: 300;
    line-height: 1.2;
}

.research__article {
    margin-top: 12px;
}

.research__article a {
    font-size: 14px;
    line-height: 1.4;
    color: #000;
}

.research__text {
    font-weight: 300;
    line-height: 1.2;
}

.research__cta {
    display: block;
    text-decoration: none;
    max-width: 420px;
    border: 1px solid transparent;
    margin: 0 auto;
    padding: 10px 12px;
    border-radius: 19px;
    background: linear-gradient(to right, #163771, #2d64a6);
    color: #fff;
    margin-top: 14px;
    transition: all .25s;
}

.research__cta:hover {
    border: 1px solid #000;
    background: #fff;
    color: #000;
}

.business-plans {
    padding: 30px 0 25px;
    background: #f7f7f7;
}

.business-plans__subheadline {
    font-weight: 300;
    line-height: 1.2;
    margin-bottom: 15px;
}

.business-plans__value-props,
.dvc__value-props {
    padding-left: 26px;
}

.business-plans__value-prop,
.dvc__value-prop {
    padding-left: 15px;
    font-size: 14px;
    text-align: left;
    margin-bottom: 10px;
    list-style-image: url(./images/bullet-inversed.png);
}

.business-plans__image {
    max-width: 236px;
    margin-top: 6px;
}

.dvc {
    padding: 25px 0;
    background: #fff;
}

.dvc__text {
    font-weight: 300;
    line-height: 1.2;
}

.dvc__image {}

.statval {
    padding: 25px 0 0;
}

.statval__subheadline {
    font-weight: bold;
    color: #14356e;
    line-height: 1.2;
    margin-bottom: 15px;
}

.statval__text {
    font-size: 14px;
    line-height: 1.2;
}

.benchmark__headline {
    font-size: 20px;
    margin-bottom: 18px;
    color: #14356e;
    font-weight: bold;
}

.benchmark__image {
    max-width: 291px;
}

.cmbs {
    padding: 25px 0;
    background: #f7f7f7;
}

.cmbs__headline {
    line-height: 1.3;
}

.cmbs__text {
    font-size: 14px;
    line-height: 1.2;
    font-weight: normal;
}

.contact {
    padding: 25px 0 41px;
    text-align: left;
    color: #050f18;
}

.contact__contact+.contact__contact {
    margin-top: 30px;
}

.contact__headline {
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 41px;
}

.contact__name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
}

.contact__role {
    font-weight: bold;
}

.contact__detail {
    display: block;
    text-align: center;
}

.contact__detail+.contact__detail {
    margin-top: 18px;
}

.contact__detail a {
    color: #000000;
    font-size: 14px;
    text-decoration: underline;
}

.contact__detail a:nth-child(2) {
    margin-left: 16px;
}

.team {
    padding: 25px 0 15px;
}

.team__headline {
    margin-bottom: 18px;
}

.team__exec-headline {
    margin-bottom: 18px;
    font-weight: bold;
}

.member {
    background: linear-gradient(to right, #163771, #2d64a6);
    border-radius: 3px;
    color: #fff;
    padding: 48px 20px;
    margin-bottom: 10px;
    position: relative;
    z-index: 0;
    box-shadow: 0 2px 4px rgba(000, 000, 000, 0.5);
}

.member a:visited {
    color: #fff;
}

.member__name {
    font-weight: bold;
    margin: 0 0 7px 0;
    font-size: 24px;
}

.member__role {
    margin-top: -6px;
    font-weight: 300;
    line-height: 1.3;
    font-size: 18px;
}

.member__creds-container {
    min-height: 220px;
    overflow: hidden;
}

.member__creds-container p {
    width: 90ch;
    margin: auto;
}

.member__creds-container.expanded {
    display: block;
    max-height: 9000px;
    opacity: 1;
    pointer-events: all;
}

.member__credentials {
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 20px;
}

.member__credential {
    padding-left: 5px;
    font-size: 14px;
    text-align: left;
    margin-bottom: 10px;
    list-style-image: url(./images/bullet-inversed.png);
    line-height: 1.2;
}

.member__credential h5 {
    font-weight: bold;
    margin-bottom: 5px;
    text-decoration: underline;
}

.member hr {
    max-width: 100%;
    margin: 25px auto;
    position: relative;
    z-index: 2;
    overflow: visible;
}

.member hr::before {
    content: "";
    backdrop-filter: blur(82px);
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    width: 158px;
    height: 14px;
}

.member hr::after {
    content: "connect and learn";
    position: absolute;
    color: #fff;
    background: -webkit-linear-gradient(right, #e5af12, #fff);
    background-clip: initial;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
    z-index: 3;
    font-style: italic;
    font-size: 14px;
    letter-spacing: 1px;
    width: 145px;
}

.member--exec .member__interactives {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.member__contact {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.member__learn {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
}

.member__button {
    position: relative;
    cursor: pointer;
    border: 1px solid transparent;
    background: transparent;
    color: #fff;
    border-radius: 100%;
    line-height: 0;
    transition: all 0.25s;
    font-size: 0;
    margin-left: 10px;
}

.member__button:hover .plus-1,
.member__button:hover .plus-2 {
    background: #e5af12;
}

.plus-1,
.plus-2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    display: inline-block;
    width: 15px;
    height: 3px;
    border-radius: 2px;
}

.plus-1 {
    transition: background-color 0.25s 0s;
}

.plus-2 {
    height: 15px;
    width: 3px;
}

.member__button:hover {
    background: #fff;
    color: #000;
    border: 1px solid transparent;
}

.member__phone {
    color:white;
}

.member__phone img {
    width: 20px;
    margin: 0 10px 0 0;
}

.member__email {
    color:white;
}

.member__email img {
    width: 20px;
    margin: 14px 10px 0 0;
}

.member__linked-in {
    width: 60px;
    height: 60px;
    padding: 10px;
    filter: invert(1);
}

.member__profile-link img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
}


.help {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.help__header {
    background-color: #0b223c;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.help__item {
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.help__text {
    color: #f7f7f7;
}

.video-button {
    cursor: pointer;
    background: none;
    outline: none;
    border: none;
    margin-bottom: 12px;
}

.video-thumbnail {
    max-width: 250px;
    border-radius: 10px;
}

.footer {
    background: linear-gradient(to right, #163771, #2d64a6);
    border-top: 1px solid #729ed5;
    padding: 20px 0;
    text-align: center;
}

.social-media {
    display: flex;
    justify-content: space-evenly;
    margin: 40px 0;
}

.social-media__link {
    transition: 0.25s all;
}

.social-media__link:hover {
    transform: rotateX(-10deg) scale(1.1);
}

.footer small {
    font-size: 10px;
    color: #fff;
}

/* Animations */

.scrolled.slide-left-offset {
    animation: slide-in-left-offset 1s ease-in-out both;
}

.scrolled.slide-left {
    animation: slide-in-left 1s ease-in-out both;
}

.scrolled.slide-right {
    animation: slide-in-right 1s ease-in-out both;
}

.scrolled.fade-in-bottom {
    animation: fade-in-bottom 1s ease-in-out both;
}

.scrolled.fade-in-bottom-1 {
    animation: fade-in-bottom 1s 0.15s ease-in-out both;
}

.scrolled.fade-in-bottom-2 {
    animation: fade-in-bottom 1s 0.3s ease-in-out both;
}

.scrolled.fade-in-bottom-3 {
    animation: fade-in-bottom 1s 0.45s ease-in-out both;
}

.scrolled.fade-in-bottom-4 {
    animation: fade-in-bottom 1s 0.6s ease-in-out both;
}

.scrolled.fade-in-bottom-5 {
    animation: fade-in-bottom 1s 0.75s ease-in-out both;
}

.scrolled.fade-in-bottom-6 {
    animation: fade-in-bottom 1s 0.9s ease-in-out both;
}

.scrolled.fade-in-bottom-7 {
    animation: fade-in-bottom 1s 1.05s ease-in-out both;
}

.scrolled.fade-in-bottom-8 {
    animation: fade-in-bottom 1s 1.2s ease-in-out both;
}

.scrolled.fade-in {
    animation: fade-in 1s ease-in-out both;
}

.scrolled.fade-in-bottom {
    animation: fade-in-bottom 1s ease-in-out both;
}

.scrolled.slide-left {
    animation: slide-in-left 1s ease-in-out both;
}

.scrolled.slide-right {
    animation: slide-in-right 1s ease-in-out both;
}

hr {
    border: 0;
    border-top: 1px solid #e5af12;
    height: 1px;
    max-width: 316px;
    margin: 16px auto;
    color: #e5af12;
}

@media (min-width: 768px) {

    .help__header {
        flex-direction: row;
    }

    .research__cta-container {
        display: flex;
    }

    .research__cta {
        max-width: 100%;
        width: calc(50% - 30px);
        padding: 10px 12px;
        border-radius: 19px;
        margin-top: 14px;
    }

    .team__two-col,
    .team__three-col {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .team__two-col {
        margin-bottom: 18px;
    }

    .team__two-col .member {
        margin: 0;
        width: calc(50% - 10px);
    }

    .staff__headline {
        margin: 26px 0 18px;
    }

    .team__three-col .member {
        margin: 0;
        width: calc(33.333% - 20px);
    }

    .plus-1,
    .plus-2 {
        width: 18px;
        height: 4px;
    }

    .plus-2 {
        height: 18px;
        width: 4px;
    }
}

@media (min-width: 1024px) {

    .video-thumbnail {
        max-width: 350px;
    }

    .fixed-banner {
        top: 95px;
    }

    .scroll-offset {
        top: -94px;
    }

    .container {
        padding: 0;
        max-width: 998px;
        margin: 0 auto;
    }

    .show-for-d-only {
        display: block;
    }

    .hide-for-d-only {
        display: none;
    }

    h2 {
        font-size: 34px;
        margin-bottom: 15px;
        line-height: 1.4;
    }

    .header {
        height: 95px;
    }

    .header a {
        padding: 8px 24px;
        font-size: 18px;
    }

    .header .svgburg {
        top: 30px;
        right: 30px;
    }

    .nav {
        font-size: 0;
    }

    .nav__list {
        top: 94px;
        width: 50%;
    }

    .hover-underline-animation {
        display: inline-block;
        position: relative;
        color: #fff;
    }

    .hover-underline-animation:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 1px;
        bottom: -5px;
        left: 0;
        background-color: #e5af12;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }

    .hover-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }

    .hero {
        padding: 54px 0 71px;
        text-align: left;
    }

    .hero .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .hero__headline,
    .hero__content {
        max-width: 100%;
        width: calc(50% - 30px);
    }

    .hero__headline {
        font-size: 40px;
        margin: 0;
        padding: 0;
    }

    .hero__subheadline {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .hero__text {
        font-size: 20px;
        line-height: 1.6;
    }

    .hero__cta {
        margin: 18px 0 0;
        font-size: 16px;
    }

    .hero hr {
        margin: 23px 0;
    }

    .research {
        padding: 58px 0 67px;
    }

    .research .container {
        max-width: 772px;
    }

    .research__subheadline {
        font-size: 18px;
        margin-bottom: 35px;
    }

    .research__article {
        margin-top: 15px;
    }

    .research__article a {
        font-size: 18px;
    }

    .research__text {
        font-size: 18px;
    }

    .research__cta {
        max-width: 100%;
        width: calc(50% - 30px);
        padding: 12px 0;
        border-radius: 30px;
        margin-top: 30px;
        font-size: 18px;
        line-height: 1;
        ;
    }

    .business-plans {
        padding: 58px 0;
        text-align: left;
    }

    .business-plans .container {
        display: flex;
        position: relative;
    }

    .business-plans__subheadline {
        font-size: 20px;
        max-width: 604px;
        margin-bottom: 44px;
        line-height: 28px;
    }

    .business-plans__value-props {
        max-width: 585px;
        font-weight: normal;
    }

    .business-plans__value-prop,
    .dvc__value-prop {
        margin-bottom: 19px;
        line-height: 24px;
        font-size: 17px;
    }

    .business-plans__image {
        max-width: 386px;
        margin-top: 6px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .dvc {
        padding: 58px 0 39px;
        text-align: left;
    }

    .dvc__headline {
        margin-bottom: 31px;
    }

    .dvc__text {
        font-size: 20px;
        line-height: 26px;
    }

    .dvc__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .dvc__content div {
        max-width: 740px;
    }

    .dvc__value-prop {
        margin-bottom: 30px;
    }

    .dvc hr,
    .benchmark hr {
        max-width: 100%;
    }

    .statval {
        padding: 58px 0 0;
    }

    .statval__subheadline {
        font-size: 28px;
        font-weight: bold;
        color: #14356e;
        margin-bottom: 16px;
    }

    .statval__subheadline2 {
        font-size: 28px;
        font-weight: bold;
        color: #000000;
        margin-bottom: 16px;
    }

    .statval__subheadline3 {
        font-size: 28px;
        font-weight: bold;
        color: rgba(215, 16, 16, 0.75);
        margin-bottom: 16px;
    }

    .statval__text {
        font-size: 18px;
        line-height: 24px;
        max-width: 954px;
        margin: 0 auto;
        font-weight: normal;
    }

    .benchmark {
        margin-bottom: 58px;
    }

    .benchmark__headline {
        font-size: 28px;
        font-weight: bold;
        color: #14356e;
    }

    .benchmark__image {
        max-width: 100%;
        margin: 0 auto;
    }

    .cmbs {
        padding: 58px 0;
    }

    .cmbs__text {
        font-size: 18px;
        line-height: 24px;
        max-width: 864px;
        margin: 0 auto;
    }

    .team {
        padding: 58px 0;
    }

    .team__headline {
        margin-bottom: 30px;
    }

    .team__exec-headline {
        font-size: 20px;
        margin-bottom: 30px;
    }

    .team__two-col {
        margin-bottom: 35px;
    }

    .team__two-col,
    .team__three-col {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .team__two-col .member {
        margin: 0;
        width: calc(50% - 20px);
        min-height: 159px;
    }

    .member__phone-flex {
        display: flex;
        align-items: center;
    }

    .member__phone-flex a.show-for-d-only {
        color: #fff;
    }

    .member .member__contact {
        display: flex;
        align-items: center;
        width: 100%;
        margin: auto;
        flex-direction: column;
    }

    .team__three-col .member {
        margin: 0;
        width: calc(33.333% - 35px);
    }

    .contact {
        padding: 62px 0 44px;
    }

    .contact .container {
        max-width: 904px;
    }

    .contact__headline {
        margin-bottom: 24px;
    }

    .contact__contacts {
        display: block;
        text-align: center;
        padding: 0;
    }

    .contact__contact+.contact__contact {
        margin: 0;
    }

    .contact__contact hr {
        margin: 14px 0;
        max-width: 100%;
    }

    .contact__name {
        font-size: 30px;
    }

    .contact__role {
        font-size: 24px;
        margin-top: -8px;
        margin-bottom: -8px;
    }

    .contact__detail {
        font-size: 20px;
    }

    .contact__detail a:nth-child(2) {
        margin-left: 12px;
    }

    .footer {
        padding: 30px 0 23px;
        text-align: left;
    }

    .footer__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .social-media {
        width: 186px;
        margin: 0;
    }

    .footer small {
        font-size: 12px;
        text-align: left;
        margin-top: 41px;
        display: block;
        font-weight: 300;
    }

    hr {
        margin: 40px auto;
    }
}

@media(min-width: 1260px) {
    .video-thumbnail {
        max-width: 400px;
    }

    .header .svgburg {
        display: none;
    }

    .header a {
        padding: 0;
    }

    .nav {
        width: 50%;
    }

    .nav__list {
        position: static;
        width: 100%;
        display: flex;
        background: none;
        backdrop-filter: none;
        box-shadow: none;
        padding: 0;
        justify-content: space-between;
        align-items: center;
    }

    .nav__list .nav__list-item {
        margin: 0;
    }

    .nav__list .nav__list-item a {
        padding: 0;
    }
}